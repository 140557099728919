/* ======================================================================== */
/* 15. Cursor */
/* ======================================================================== */
var Cursor = function () {

	var $cursor = $('#js-cursor');

	this.drawLoading = function () {
		drawLoading();
	};

	this.finishLoading = function () {
		finishLoading();
	};

	// don't launch on mobiles
	if (!$cursor.length || Modernizr.touchevents) {
		return;
	}

	var
		tl = new TimelineMax(),
		$follower = $('.cursor__follower'),
		// $inner = $follower.find('svg'),
		$inner = $follower.find('#inner'),
		$outter = $follower.find('#outter'),
		$triggers = $('#js-burger, a'),
		posX = 0,
		posY = 0,
		mouseX = 0,
		mouseY = 0;

	start();

	function start() {

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		TweenMax.set($cursor, {
			display: 'block',
			y: '-50%',
			x: '-50%'
		});

		TweenMax.to({}, 0.01, {
			repeat: -1,
			onRepeat: function () {

				posX += (mouseX - posX) / 6;
				posY += (mouseY - posY) / 6;

				TweenMax.set($cursor, {
					left: posX,
					top: posY,
					// opacity: .5
				});

			}
		});

		TweenMax.set($outter, {
			drawSVG: '0%',
		});

		$document.on('mousemove', function (e) {
			mouseX = e.pageX;
			mouseY = e.pageY;
		});

		$document.on('mouseenter', 'a, #js-burger', function () {

				TweenMax.to($cursor, 1.2, {
					scale: 1.5,
					ease: Elastic.easeOut.config(1, 0.4),
				});

				TweenMax.to($inner, 0.6, {
					opacity: 1
				});

			})
			.on('mouseleave', 'a, #js-burger', function () {

				TweenMax.to($cursor, 1.2, {
					scale: 1,
					ease: Elastic.easeOut.config(1, 0.4),
				});

				TweenMax.to($inner, 0.6, {
					opacity: .6
				});

			});

		$document.on('mouseenter', '.slider__arrow, .slider__dot, .social__item a, .section-video__link', function () {

				TweenMax.to($cursor, 0.3, {
					scale: 0,
					ease: Expo.easeInOut
					// ease: Elastic.easeOut.config(1, 0.4),
				});

				TweenMax.to($inner, 0.3, {
					opacity: 1
				});

			})
			.on('mouseleave', '.slider__arrow, .slider__dot, .social__item a, .section-video__link', function () {

				TweenMax.to($cursor, 1.2, {
					scale: 1,
					ease: Elastic.easeOut.config(1, 0.4),
					// delay: 0.3
				});

				TweenMax.to($inner, 0.6, {
					opacity: .6,
					// delay: 0.3
				});

			});

	}

	function drawLoading() {

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		tl
			.stop().clear().play()
			.add(TweenMax.to($cursor, 1.2, {
				scale: 1.5,
				ease: Elastic.easeOut.config(1, 0.4)
			}))
			.fromTo($outter, 1.2, {
				drawSVG: '0%',
			}, {
				drawSVG: '100%',
				ease: Expo.easeInOut
			}, '0');

	}

	function finishLoading() {

		if (!$cursor.length || Modernizr.touchevents) {
			return;
		}

		tl.stop().clear().play()
			.to($outter, 0.6, {
				drawSVG: '100%',
				ease: Expo.easeInOut
			})
			.to($outter, 0.6, {
				autoAlpha: 0
			}, '0')
			.set($outter, {
				drawSVG: '0%',
				autoAlpha: 1
			})
			.to($cursor, 1.2, {
				scale: 1,
				ease: Elastic.easeOut.config(1, 0.4),
			});

	}

}
