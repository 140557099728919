/* ======================================================================== */
/* 32. SectionHalfScreenSlider */
/* ======================================================================== */
var SectionHalfScreenSlider = function ($scope) {

	var $target = $scope.find('.section-halfscreen-slider[data-os-animation]');

	if (!$target.length) {

		return;

	}

	var
		$slider = $target.find('.js-slider-halfscreen'),
		$wrapperImg = $('.slider-halfscreen__images-slide .slider-halfscreen__images-slide-inner'),
		$heading = $slider.find('h2'),
		$description = $slider.find('p'),
		$link = $slider.find('.slider-halfscreen__link span'),
		$linkLine = $slider.find('.slider-halfscreen__link-line'),
		$prev = $slider.find('.slider-halfscreen__arrow_prev .slider__arrow-inner'),
		$next = $slider.find('.slider-halfscreen__arrow_next .slider__arrow-inner');

	prepare().then(function () {
		animate();
	});

	function prepare() {

		return new Promise(function (resolve, reject) {

			var tl = new TimelineMax();

			tl
				.add(function () {
					new SliderHalfScreen($slider);
				})
				.set($prev, {
					y: '-50px',
					autoAlpha: 0
				})
				.set($next, {
					y: '50px',
					autoAlpha: 0
				})
				.set($wrapperImg, {
					autoAlpha: 0,
					scale: 1.1
				})
				.add(hideWords($link, '0', '0', '30px'))
				.set($linkLine, {
					scaleX: 0,
					transformOrigin: 'left center'
				})
				.add(function () {
					setLines($description);
				})
				.add(hideWords($heading, '0', '0', '-50px'))
				.add(function () {
					resolve(true);
				});

		});

	}

	function animate() {

		var tl = new TimelineMax();

		tl
			.add(animateWords($slider.find('.swiper-slide-active h2'), 1.2, 0.02, true), '0')
			.add(animateLines($slider.find('.swiper-slide-active p'), 1.2, 0.01), '0.6')
			.add(animateWords($link, 1.2, 0.02), '1.2')
			.to($linkLine, 1.2, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, '1.2')
			.to($wrapperImg, 1.2, {
				scale: 1,
			}, '0.3')
			.to($wrapperImg, 1.2, {
				autoAlpha: 1,
			}, '0.3')
			.to([$prev, $next], 1.2, {
				autoAlpha: 1,
				y: '0px'
			}, '0');

		createOSScene($target, tl);

	}


}
