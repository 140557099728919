/* ======================================================================== */
/* 22. lazyLoad */
/* ======================================================================== */
function lazyLoad($scope = document, $elements = $document.find('.lazy')) {

	var $images = $elements.find('img[data-src]');
	var $backgrounds = $scope.find('.lazy-bg[data-src]');

	setResponsivePaddingBottom($images).then(loadImages());

	function setResponsivePaddingBottom($images) {

		return new Promise(function (resolve, reject) {

			$images.each(function () {

				var
					$el = $(this),
					elWidth = $el.attr('width'),
					elHeight = $el.attr('height');

				// we need both width and height of element
				// to calculate proper value for "padding-bottom" hack
				if (!elWidth || !elHeight) {
					return;
				}

				var elPB = (elHeight / elWidth) * 100 + '%';

				$el.removeAttr('width').removeAttr('height').removeAttr('src');

				$el.parent().css({
					'padding-bottom': elPB,
					'animation-name': 'loading',
					'width': '100%',
					'height': '0'
				});

			});

			resolve();

		});

	};

	function loadImages() {

		return new Promise(function (resolve, reject) {

			var instance = $images.Lazy({
				chainable: false,
				afterLoad: function (el) {

					$(el).parent().css({
						'padding-bottom': '',
						'width': '',
						'height': '',
						'animation-name': 'none',
						'background-color': 'initial'
					});

					setTimeout(function () {
						new Grid();
					}, 300);

				}

			});

			var instanceBackgrounds = $backgrounds.Lazy({
				chainable: false
			});

			// update lazy load instance when smooth scroll is enabled
			if (window.SB !== undefined && instance.config && instance.config('delay') !== 0) {

				window.SB.addListener(function () {
					instance.update(true);
					instanceBackgrounds.update(true);
				});

			}

			resolve(true);

		});
	};

}
