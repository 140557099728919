/* ======================================================================== */
/* 4. PJAX Finish Loading */
/* ======================================================================== */
function PJAXFinishLoading(data) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax();

		tl
			.add(function () {
				window.SMController.enabled(true);
				window.SMController.update(true);
			})
			.set(window.$body, {
				className: '-=body_lock-scroll'
			})
			.add(function () {
				window.InteractiveCursor.finishLoading();
			})
			.set(window.$preloader, {
				autoAlpha: 0
			})
			.set(window.$wrapperBackgrounds, {
				autoAlpha: 0,
				zIndex: -1,
				clearProps: 'width,height,left,right,top,bottom,backgroundColor',
			})
			.set(window.$backgroundsOverlay, {
				autoAlpha: 1
			})
			.set(window.$backgrounds, {
				transition: '',
				clearProps: 'transform,width,height',
				className: '-=selected',
			})
			.set(window.$backgrounds, {
				className: '-=active',
			})
			.set(window.$overlay, {
				className: '-=lockhover'
			}, '0.6')
			.set(window.$overlay, {
				className: '-=intransition'
			}, '0.6')
			.add(function () {
				resolve(true);
			});

	});

}
