/* ======================================================================== */
/* 44. SliderText */
/* ======================================================================== */
var SliderText = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$upper = $slider.find('.js-slider-text__upper'),
		$lower = $slider.find('.js-slider-text__lower'),
		options = {
			speed: 2000,
			slidesPerView: 'auto',
			centeredSlides: true,
			loop: true,
			touchRatio: 2,
			mousewheel: {
				eventsTarged: '.page-wrapper',
				releaseOnEdges: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					centeredSlides: true,
				}
			},
			controller: {
				by: 'container'
			}
		};

	var sliderUpper = new Swiper($upper, options);
	var sliderLower = new Swiper($lower, options);

	sliderUpper.controller.control = sliderLower;
	sliderLower.controller.control = sliderUpper;

}
