/* ======================================================================== */
/* 27. SmoothScroll */
/* ======================================================================== */
var SmoothScroll = function () {

	var $smoothScroll = $('#js-smooth-scroll');

	// don't launch on mobiles
	if (!$smoothScroll.length || Modernizr.touchevents) {
		return;
	}

	$smoothScroll.addClass('smooth-scroll');

	window.SB = Scrollbar.init($smoothScroll[0], {
		renderByPixels: true,
		damping: 0.05
	});

}
