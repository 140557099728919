/* ======================================================================== */
/* 21. Header */
/* ======================================================================== */
var Header = function () {

	var $overlay = $('.header__wrapper-overlay-menu');

	if (!$overlay.length) {
		return;
	}

	var
		$menuLinks = $overlay.find('.menu-overlay > li > a'),
		$allLinks = $overlay.find('a'),
		$submenu = $overlay.find('.menu-overlay .sub-menu'),
		$submenuButton = $('#js-submenu-back'),
		$submenuLinks = $submenu.find('> li > a'),
		$overlayWidgets = $overlay.find('.header__wrapper-overlay-widgets'),
		$widgets = $overlay.find('.figure-property'),
		$headerLeft = $('.header__col-left'),
		OPEN_CLASS = 'header__burger_opened';

	clickBurger();

	function setOverlayMenu() {

		TweenMax.set(window.$overlay, {
			scaleX: 0,
			autoAlpha: 0,
			className: '-=opened',
			// z: 0.01,
			// force3D: true
		});

		TweenMax.set([$submenu, $submenuButton], {
			autoAlpha: 0
		});

		TweenMax.set($submenu, {
			className: '-=opened'
		});

		TweenMax.set($overlayWidgets, {
			scaleY: 0,
			transformOrigin: 'bottom center',
		});

		TweenMax.set(window.$wrapperBackgrounds, {
			clearProps: 'width,height,left,right,top,bottom,backgroundColor',
		});

		setLines($overlayWidgets);
		hideWords($menuLinks, 0, 0, '-100px');
		hideWords($submenuLinks, 0, 0, '-30px');
		$allLinks.removeClass('selected');
	}

	function closeOverlayMenu(hideBackgrounds) {

		var
			tl = new TimelineMax(),
			$submenuLinksCurrent = $submenu.filter('.opened').find($submenuLinks),
			$pageWrapper = $('.page-wrapper'),
			$layers = [];

		$layers.push(window.$overlay);

		if (hideBackgrounds == true) {
			$layers.push(window.$wrapperBackgrounds);
		}

		tl.timeScale(1.5);

		return tl
			.set(window.$overlay, {
				className: '+=intransition',
				transformOrigin: 'right center',
				zIndex: 500,
				// z: 0.01,
				// force3D: true
			}, '0')
			.set(window.$wrapperBackgrounds, {
				transformOrigin: 'right center',
				zIndex: 100,
			}, '0')
			.set($burger, {
				className: '-=header__burger_opened'
			}, '0')
			.set($pageWrapper, {
				x: '-5vw',
				force3D: true,
				transformOrigin: 'right center',
			}, '0')
			.add(hideWords($menuLinks, 1.2, 0.01, '100px', true, 'start'), '0')
			.add(hideWords($submenuLinksCurrent, 1.2, 0.01, '30px', true, 'start'), '0')
			.add(hideLines($widgets), '0')
			.to($overlayWidgets, 0.6, {
				scaleY: 0,
				transformOrigin: 'top center',
				ease: Expo.easeInOut
			}, '1')
			.to($layers, 1.2, {
				scaleX: 0,
				ease: Expo.easeInOut
			}, '1')
			.to($pageWrapper, 2.4, {
				x: '0vw',
				force3D: true,
				autoAlpha: 1,
				ease: Expo.easeInOut
			}, '0.4')
			.to($submenuButton, 0.6, {
				x: '-10px',
				autoAlpha: 0
			}, '0')
			.fromTo($headerLeft, 2.4, {
				x: '-50px',
			}, {
				x: '0px',
				autoAlpha: 1,
				ease: Expo.easeInOut
			}, '0.4')
			.set(window.$overlay, {
				className: '-=opened'
			}, '1')
			.add(function () {
				lockScroll(false);
			}, '0')
			.add(function () {
				setOverlayMenu();
			}, '2')
			.set(window.$overlay, {
				className: '-=intransition'
			});


	};

	function openOverlayMenu() {

		var
			tl = new TimelineMax(),
			$pageWrapper = $('.page-wrapper');

		tl
			.add(function () {
				setOverlayMenu();
			})
			.set(window.$overlay, {
				className: '+=intransition',
				autoAlpha: 1,
				transformOrigin: 'left center',
				zIndex: 500
			})
			.set(window.$wrapperBackgrounds, {
				scaleX: 0,
				transformOrigin: 'left center',
				autoAlpha: 1,
				zIndex: 100,
			})
			.set(window.$backgroundsOverlay, {
				autoAlpha: 0.6
			})
			.set(window.$overlay, {
				className: '+=opened'
			})
			.to($pageWrapper, 1.2, {
				x: '10vw',
				force3D: true,
				// autoAlpha: 0,
				transformOrigin: 'left center',
				ease: Expo.easeInOut
			})
			.to($headerLeft, 1.2, {
				x: '50px',
				autoAlpha: 0,
				ease: Expo.easeInOut
			}, '0')
			.to([window.$overlay, window.$wrapperBackgrounds], 0.6, {
				scaleX: 1,
				ease: Expo.easeInOut
			}, '0.2')
			.add(animateWords($menuLinks, 1.8, 0.01, true, false, '-=1.5'), '0.6')
			.to($overlayWidgets, 1.2, {
				scaleY: 1,
				ease: Expo.easeInOut,
			}, '0.6')
			.add(animateLines($widgets), '1.2')
			.set($overlay, {
				className: '-=intransition'
			}, '1.2')
			.add(function () {
				lockScroll(true);
			});

	};

	function clickBurger() {

		window.$burger.off().on('click', function (e) {

			e.preventDefault();

			if (!$overlay.hasClass('intransition')) {

				if (window.$burger.hasClass(OPEN_CLASS)) {
					closeOverlayMenu(true);
					window.$burger.removeClass(OPEN_CLASS);
				} else {
					openOverlayMenu();
					window.$burger.addClass(OPEN_CLASS);
				}

			}

		});

	}

	this.hideOverlayMenu = function (hideBackgrounds) {

		return closeOverlayMenu(hideBackgrounds);

	}

}
