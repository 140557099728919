/* ======================================================================== */
/* 24. parallax */
/* ======================================================================== */
var Parallax = function ($scope) {

	var $wrapper = $scope.find('[data-art-parallax]');

	if (!$wrapper.length) {
		return;
	}

	$wrapper.each(function () {

		var
			$current = $(this),
			$img = $current.find('img, .art-parallax__bg'),
			factor = parseFloat($current.data('art-parallax-factor')) || 0.3,
			factorTo = Math.abs(factor) * 100,
			factorFrom = -1 * factor * 100,
			factorScale = 1 + Math.abs(factor),
			sceneDuration = window.innerHeight + $current.height();

		if ($img.is('img')) {
			sceneDuration = window.innerHeight + $current.parent().height();
		}

		if (!$img.length) {
			return;
		}

		if (factorFrom > 0) {
			factorScale = factorScale * factorScale;
			factorTo = factor * 100;
		}

		var tl = new TimelineMax();

		TweenMax.set($img, {
			scale: factorScale,
			transformOrigin: 'center center',
			force3D: true,
			rotationZ: 0.01,
			ease: Expo.easeInOut
		});

		tl.fromTo($img, 0.3, {
			y: factorFrom + '%',
			force3D: true,
			ease: Linear.easeNone,
		}, {
			y: factorTo + '%',
			force3D: true,
			ease: Linear.easeNone,
		});

		var scene = new ScrollMagic.Scene({
				triggerElement: $current,
				triggerHook: 1,
				duration: sceneDuration
			})
			.setTween(tl)
			.addTo(window.SMController)
			.update(true);

		// update scene when smooth scroll is enabled
		if (window.SB !== undefined) {

			window.SB.addListener(function () {
				scene.refresh();
			});

		}

	});

}
