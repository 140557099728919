/* ======================================================================== */
/* 33. SectionHeadingsSlider */
/* ======================================================================== */
var SectionHeadingsSlider = function ($scope) {

	var $target = $scope.find('.section-headings-slider[data-os-animation]');

	if (!$target.length) {

		return;

	}

	var
		$slider = $target.find('.js-slider-headings'),
		$counter = $slider.find('.slider-headings__counter'),
		$heading = $slider.find('h2'),
		$prev = $slider.find('.slider-headings__arrow_prev .slider__arrow-inner'),
		$next = $slider.find('.slider-headings__arrow_next .slider__arrow-inner'),
		$dots = $slider.find('.slider__dots'),
		$backgrounds = $slider.find('.slider__background');

	prepare().then(function () {
		animate();
	})

	function prepare() {

		return new Promise(function (resolve, reject) {

			var tl = new TimelineMax();

			tl
				.add(function () {
					new SliderHeadings($slider);
				})
				.set($prev, {
					x: '-50px',
					autoAlpha: 0
				})
				.set($next, {
					x: '50px',
					autoAlpha: 0
				})
				.add(hideWords($heading, '0', '0', '-100px'))
				.set($counter, {
					autoAlpha: 0,
				})
				.set($dots, {
					autoAlpha: 0,
					y: '50px'
				})
				.add(function () {
					resolve(true);
				});

		});

	}

	function animate() {

		var tl = new TimelineMax();

		tl
			.add(animateWords($slider.find('.swiper-slide-active h2'), 1.2, 0.02, true), '0')
			.to([$prev, $next], 1.2, {
				autoAlpha: 1,
				x: '0px'
			}, '0')
			.to($dots, 1.2, {
				autoAlpha: 1,
				y: '0px'
			}, '0')
			.to($counter, 1.2, {
				autoAlpha: 1,
			}, '0.3')
			.add(function () {
				new SliderBackgrounds($backgrounds);
			}, '0.6');

		createOSScene($target, tl);

	}


}
