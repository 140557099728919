/* ======================================================================== */
/* 25. Preloader */
/* ======================================================================== */
function Preloader() {

	var tl = new TimelineMax();

	return new Promise(function (resolve) {

		tl
			.add(function () {
				resolve(true);
			}, '0.3');


	});

}
