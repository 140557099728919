/* ======================================================================== */
/* 43. SliderTestimonials */
/* ======================================================================== */
var SliderTestimonials = function ($scope) {

	var $slider = $scope.find('.js-slider-testimonials');

	if (!$slider.length) {
		return;
	}

	$slider.each(function () {

		var
			$current = $(this).find('.js-slider-testimonials__items'),
			$header = $(this).find('.js-slider-testimonials__header');

		var sliderItems = new Swiper($current, {
			autoHeight: true,
			autoplay: {
				enabled: $current.data('autoplay-enabled') || false,
				delay: $current.data('autoplay-delay') || 6000,
			},
			speed: $current.data('speed') || 1200,
			preloadImages: false,
			lazy: {
				loadPrevNext: true,
				loadOnTransitionStart: true
			},
			watchSlidesProgress: true,
			watchSlidesVisibility: true,
			pagination: {
				el: '.js-slider-testimonials__dots',
				type: 'bullets',
				bulletElement: 'div',
				clickable: true,
				bulletClass: 'slider__dot',
				bulletActiveClass: 'slider__dot_active'
			},
		});

		var sliderHeader = new Swiper($header, {
			slideToClickedSlide: true,
			slidesPerView: 4.5,
			centeredSlides: true,
			speed: $current.data('speed') || 1200,
			autoplay: {
				enabled: $current.data('autoplay-enabled') || false,
				delay: $current.data('autoplay-delay') || 6000,
			},
			breakpoints: {
				1200: {
					slidesPerView: 3.5
				},
				767: {
					slidesPerView: 2
				},
				480: {
					slidesPerView: 1
				}
			}
		});

		// update height after images are loaded
		sliderItems.on('lazyImageReady', function () {
			sliderItems.update();
		});

		sliderItems.controller.control = sliderHeader;
		sliderHeader.controller.control = sliderItems;

	});

}
