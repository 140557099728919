/* ======================================================================== */
/* 6. PJAX Prepare Transition */
/* ======================================================================== */
function PJAXPrepareTransition(data) {

	return new Promise(function (resolve, reject) {

		var
			tl = new TimelineMax(),
			$trigger = $(data.trigger);

		tl
			.add(function () {
				window.InteractiveCursor.drawLoading();
			})
			.set(window.$curtains, {
				scaleX: 0,
				transformOrigin: 'left center',
			})
			.set(window.$preloader, {
				autoAlpha: 1
			})
			.set(window.$overlay, {
				className: '+=intransition'
			})
			.set(window.$overlay, {
				className: '+=lockhover'
			})
			.set($trigger, {
				className: '+=selected'
			})
			.add(function () {
				resolve(true);
			});

	});

}
