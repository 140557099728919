/* ======================================================================== */
/* 30. SectionComposition */
/* ======================================================================== */
var SectionComposition = function ($scope) {

	var $target = $scope.find('.section-composition__content[data-os-animation]');

	if (!$target.length) {

		return;

	}

	$target.each(function () {

		var
			tl = new TimelineMax(),
			$current = $(this),
			$headlineProperty = $current.find('.figure-property__headline'),
			$property = $current.find('.figure-property');

		prepare();
		animate();

		function prepare() {

			TweenMax.set($headlineProperty, {
				scaleX: 0,
				transformOrigin: 'left center'
			});

		}

		function animate() {

			tl
				.to($headlineProperty, 1.2, {
					scaleX: 1,
					ease: Expo.easeInOut,
					transformOrigin: 'left center'
				})
				.add(animateLines($property, 0.8, 0.03), '-=1.2');

			createOSScene($current, tl, $property);

		}

	})

}
