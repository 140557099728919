/* ======================================================================== */
/* 40. sliderHalfScreen */
/* ======================================================================== */
var SliderHalfScreen = function ($slider) {

	if (!$slider.length) {
		return;
	}

	var
		$heading = $slider.find('h2'),
		$description = $slider.find('p'),
		tl = new TimelineMax(),
		$sliderImg = $slider.find('.js-slider-halfscreen__images'),
		$sliderContent = $slider.find('.js-slider-halfscreen__content'),
		overlapFactor = $sliderImg.data('overlap-factor') || 0;

	createSliders();
	hoverLinks();

	function createSliders() {

		var sliderImg = new Swiper($sliderImg, {
			autoplay: {
				enabled: $sliderImg.data('autoplay-enabled') || false,
				delay: $sliderImg.data('autoplay-delay') || 6000,
				disableOnInteraction: true
			},
			direction: 'vertical',
			preloadImages: true,
			lazy: {
				loadPrevNext: true,
				loadOnTransitionStart: true
			},
			speed: $sliderImg.data('speed') || 1200,
			simulateTouch: false,
			watchSlidesProgress: true,
			on: {
				progress: function () {
					var swiper = this;
					for (var i = 0; i < swiper.slides.length; i++) {

						var slideProgress = swiper.slides[i].progress,
							innerOffset = swiper.width * overlapFactor,
							innerTranslate = slideProgress * innerOffset;

						try {
							TweenMax.set(swiper.slides[i].querySelector('.slider-halfscreen__bg'), {
								y: innerTranslate + 'px',
								transition: swiper.params.speed + 'ms',
								rotationZ: 0.01,
								force3D: true
							});
						} catch (error) {

						}

					}
				},
				touchStart: function () {
					var swiper = this;
					for (var i = 0; i < swiper.slides.length; i++) {
						try {
							TweenMax.set(swiper.slides[i].querySelector('.slider-halfscreen__bg'), {
								transition: '',
								rotationZ: 0.01,
								force3D: true
							});
						} catch (error) {

						}

					}
				},
			}
		});

		var sliderContent = new Swiper($sliderContent, {
			simulateTouch: false,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			mousewheel: {
				eventsTarged: '.page-wrapper',
				releaseOnEdges: true,
			},
			navigation: {
				nextEl: '.js-slider-halfscreen__next',
				prevEl: '.js-slider-halfscreen__prev',
			},
			speed: $sliderImg.data('speed') || 1200,
			allowTouchMove: false,
			breakpoints: {
				992: {
					autoHeight: true
				}
			}
		});

		sliderContent
			.on('slideNextTransitionStart', function () {

				var
					$activeSlide = $(sliderContent.slides[sliderContent.activeIndex]),
					$activeHeading = $activeSlide.find($heading),
					$activeDescription = $activeSlide.find($description);

				tl.clear();

				$heading.each(function () {
					tl
						.add(hideWords($(this), 0.3, 0.02, '50px', true), '0')
						.add(hideWords($(this), '0', '0', '-50px'));
				});

				$description.each(function () {
					tl.add(hideLines($(this), 0.6, 0.01, '100%', true), '0');
				});

				tl
					.add(animateWords($activeHeading, 1.2, 0.02, true))
					.add(animateLines($activeDescription, 1.2, 0.01, true), '-=1.2');

			})
			.on('slidePrevTransitionStart', function () {

				var
					$activeSlide = $(sliderContent.slides[sliderContent.activeIndex]),
					$activeHeading = $activeSlide.find($heading),
					$activeDescription = $activeSlide.find($description);

				tl.clear();

				$heading.each(function () {
					tl
						.add(hideWords($(this), 0.3, 0.02, '-50px', false), '0')
						.add(hideWords($(this), '0', '0', '50px'));
				});

				$description.each(function () {
					tl.add(hideLines($(this), 0.6, 0.01, '100%', true), '0');
				});

				tl
					.add(animateWords($activeHeading, 1.2, 0.02, false))
					.add(animateLines($activeDescription, 1.2, 0.01), '-=1.2');

			});

		sliderImg.controller.control = sliderContent;
		sliderContent.controller.control = sliderImg;

	}

	function hoverLinks() {

		$document
			.on('mouseenter touchstart', '.slider-halfscreen__link', function () {

				var $targetBackground = $sliderImg.find('.swiper-slide-active .slider-halfscreen__images-slide-inner'),
					$linkLine = $sliderContent.find('.swiper-slide-active .slider-halfscreen__link-line');

				if (!$targetBackground.length) {
					return;
				}

				TweenMax.to($targetBackground, 0.6, {
					scale: 1.05,
					ease: Expo.easeInOut
				});

				TweenMax.to($linkLine, 0.6, {
					width: '70px',
					ease: Expo.easeInOut
				});

			})
			.on('mouseleave touchend', '.slider-halfscreen__link', function () {

				var $targetBackground = $sliderImg.find('.swiper-slide-active .slider-halfscreen__images-slide-inner'),
					$linkLine = $sliderContent.find('.swiper-slide-active .slider-halfscreen__link-line');

				if (!$targetBackground.length) {
					return;
				}

				TweenMax.to($targetBackground, 0.6, {
					scale: 1,
					ease: Expo.easeInOut
				});

				TweenMax.to($linkLine, 0.6, {
					width: '60px',
					ease: Expo.easeInOut
				});

			});

	}

}
